import { colorLookup } from '@medifind/utils';

const IconManSilhouette = (props) => {
  const { color } = props;
  const colorCode = colorLookup(color) ?? colorLookup('ebonyClay');
  return (
    <svg width="64" height="68" viewBox="0 0 64 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m41.149 48.808c-0.18-2.4155-0.2305-4.8387-0.1513-7.2594l-0.0029 0.10636c2.1891-2.2427 3.5544-5.1458 3.8778-8.2455l0.0058-0.06042c0.9862-0.08043 2.5426-1.0292 2.9964-4.7897 0.137-0.64772 0.0853-1.3205-0.149-1.9403-0.2342-0.61982-0.6415-1.1613-1.1746-1.5615l-0.0088-0.0058c1.6059-4.7725 4.9397-19.539-6.1701-21.062-1.1433-1.9838-4.0699-2.99-7.8749-2.99-15.223 0.276-17.059 11.359-13.731 24.052-0.5373 0.40395-0.9472 0.95082-1.1816 1.5766-0.2344 0.62573-0.2836 1.3045-0.142 1.9568l-0.0029-0.02297c0.4655 3.7547 2.0102 4.7093 2.9964 4.7898 0.3459 3.1342 1.7546 6.0605 3.9971 8.303 0.0737 2.4632 0.0174 4.9285-0.1688 7.3859l0.0146-0.23283c-3.0197 8.004-23.348 5.7557-24.279 21.192h65.332c-0.9368-15.436-21.172-13.188-24.186-21.192z"
        fill={colorCode}
      />
    </svg>
  );
};

export { IconManSilhouette as default, IconManSilhouette };
