import { clientFetch } from '../clientFetch';

export const searchGeoCoordsMapbox = (pos) => {
  return clientFetch(`/location/search/geoCoords`, {
    params: { lat: pos.coords.latitude, lon: pos.coords.longitude },
  });
};

export const searchGeoCoordsGoogle = (pos) => {
  return clientFetch(`/location/geolocate`, {
    params: { lat: pos.coords.latitude, lon: pos.coords.longitude },
  });
};
