import { searchGeoCoordsGoogle } from '@medifind/interface';

const LOCATION_BROWSER = 'browser';

export const setCurrentLocation = (setLocation) => {
  return new Promise(function (resolve, reject) {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        if (pos.coords.latitude && pos.coords.longitude)
          searchGeoCoordsGoogle(pos)
            .then(({ result }) => {
              const { addressLine1, placeId, locationString, city, countryCode, country, stateProvinceCode, stateProvince, zipPostalCode } =
                result;
              const fidelity = getFidelity(addressLine1, zipPostalCode, city, stateProvinceCode, countryCode);
              const defaultRadius = getDefaultRadius(fidelity);
              const { latitude, longitude } = pos.coords;
              const data = {
                fidelity,
                lat: latitude,
                lon: longitude,
                city,
                countryCode,
                country,
                stateProvinceCode,
                stateProvince,
                zipPostalCode,
                placeId,
                streetAddress: addressLine1,
                locationString,
                locationOrigin: LOCATION_BROWSER,
                radius: defaultRadius,
              };
              setLocation(data);
              resolve();
            })
            .catch((err) => {
              console.error(err);
              reject(new Error('Could not get location'));
            });
      },
      (err) => {
        console.error(err);
        if (err?.code === 1) {
          reject(new Error('Location permission denied by user'));
        } else {
          reject(new Error('Unknown error'));
        }
      },
      { maximumAge: 7200000 },
    );
  });
};

export const getFidelity = (street, zipPostalCode, city, state, country) => {
  // 2 == Postal Code
  if (street) {
    return 1;
  } else if (zipPostalCode) {
    return 2;
  } else if (city) {
    return 3;
  } else if (state) {
    return 4;
  } else if (country) {
    return 5;
  } else {
    return 6; // Global
  }
};

export const getDefaultRadius = (fidelity) => {
  if (fidelity === 6 || !fidelity) {
    return 'global';
  } else if (fidelity === 5) {
    return 'national';
  } else if (fidelity === 4) {
    return 'state';
  } else {
    return 25;
  }
};

export const getGoogleMapsSearchUrl = ({ centerName, address1, city, latitude, longitude, state, zip }) => {
  const queryParams = [
    latitude && longitude ? `ll=${latitude + ',' + longitude}` : null,
    [centerName, city, state, zip].map((d) => d?.toLowerCase()?.trim()).filter(Boolean).length > 0
      ? `query=${encodeURIComponent(
          [centerName, city, state, zip]
            .map((d) => d?.toLowerCase()?.trim())
            .filter(Boolean)
            .join(' ')
            .replace(/\s+/g, '+'),
        )}`
      : null,
    [address1, city, state, zip].map((d) => d?.toLowerCase()?.trim()).filter(Boolean).length > 0
      ? `near=${[address1, city, state, zip]
          .map((d) => d?.toLowerCase()?.trim())
          .filter(Boolean)
          .join(' ')
          .replace(/\s+/g, '+')}`
      : null,
  ]
    .filter(Boolean)
    .join('&');

  return Boolean(queryParams) ? 'https://www.google.com/maps/search/?api=1&' + queryParams : null;
};
