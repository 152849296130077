import { colorLookup } from '@medifind/utils';

const IconNonBinarySilhouette = (props) => {
  const { color } = props;
  const colorCode = colorLookup(color) ?? colorLookup('ebonyClay');
  return (
    <svg width="64" height="68" viewBox="0 0 64 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m44.921 7.2542c-3.1156-4.0332-7.4671-6.2542-12.27-6.2542-4.8287 0-9.1946 2.2076-12.296 6.2158-3.1348 4.0524-4.6622 9.5598-4.3035 15.507 0.7109 11.733 8.1572 21.277 16.599 21.277 8.4422 0 15.876-9.5426 16.598-21.273 0.36334-5.8933-1.1736-11.389-4.3276-15.472z"
        fill={colorCode}
      />
      <path
        d="m41.149 48.501c-0.18-2.4505-0.2305-7.1534-0.1513-9.6092l-3e-3 0.1079c2.1891-2.2752-18.804-2.275-16.561 0 0.0737 2.4989 0.0174 7.2445-0.1688 9.7375l0.0147-0.2362c-3.0197 8.12-23.348 5.8391-24.279 21.499h65.332c-0.9368-15.66-21.172-13.379-24.186-21.499z"
        fill={colorCode}
      />
    </svg>
  );
};

export { IconNonBinarySilhouette as default, IconNonBinarySilhouette };
