import { clientFetch } from '../clientFetch';

/*
 * Params:
 * countryCode
 */

export const getLocationByCountry = (params) => {
  return clientFetch('/suggestions/getLocations', { params });
};
/*
 * Params:
 * continentCode
 */
export const getCountryByContinent = (params) => {
  return clientFetch('/suggestions/getCountryByContinent', { params });
};
/*
 * Params:
 * countryCode
 */
export const getCountryByCode = (params) => {
  return clientFetch('/suggestions/getCountryByCode', { params });
};
