import { colorLookup } from '@medifind/utils';

const IconWomanSilhouette = (props) => {
  const { color } = props;
  const colorCode = colorLookup(color) ?? colorLookup('ebonyClay');
  return (
    <svg width="64" height="68" viewBox="0 0 64 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m39.753 43.458h0.4365c6.096 0.48503 11.969 0.94862 13.276-0.04848 2.0875-1.5912-5.1381-4.0432-5.1381-24.628 0-10.401-6.2822-17.782-15.815-17.782-0.081 0-0.1605 0.005315-0.2404 0.006925-0.0224-6.542e-4 -0.0453-9.763e-4 -0.0672-0.00226v-0.00193c-0.0357 0-0.0714-0.002899-0.1073-0.002899-0.0112 0-0.0221 6.442e-4 -0.0335 6.442e-4 -0.0107 0-0.0215-6.442e-4 -0.0329-6.442e-4 -0.0105 0-0.0209 6.442e-4 -0.0314 9.6628e-4 -0.0104-3.2208e-4 -0.0208-9.6628e-4 -0.0313-9.6628e-4 -0.0112 0-0.022 6.442e-4 -0.0329 6.442e-4 -0.0112 0-0.0222-6.442e-4 -0.0335-6.442e-4 -0.0358 0-0.0715 0.002899-0.1073 0.002899v0.00193c-0.022 0.00129-0.0448 0.00161-0.0672 0.00225-0.08-0.00161-0.1596-0.006925-0.2404-0.006925-9.5322 0-15.815 7.3806-15.815 17.782 0 20.584-7.2252 23.037-5.1381 24.628 1.3071 0.99663 7.1803 0.53342 13.276 0.04848h0.4366z"
        fill={colorCode}
      />
      <path
        d="m41.149 48.501c-0.18-2.4505-0.2305-8.1534-0.1513-10.609l-3e-3 0.1079c2.1891-2.2752-18.804-2.275-16.561 0 0.0737 2.4989 0.0174 8.2445-0.1688 10.738l0.0147-0.2362c-3.0197 8.12-23.348 5.8391-24.279 21.499h65.332c-0.9368-15.66-21.172-13.379-24.186-21.499z"
        fill={colorCode}
      />
    </svg>
  );
};

export { IconWomanSilhouette as default, IconWomanSilhouette };
