import { abortableFetch, clientFetch } from '../clientFetch';

export const magicFilter = (params, options) => {
  return clientFetch(
    '/autocomplete/magic',
    {
      params,
    },
    options?.controller,
  );
};
export const magicAutocomplete = (params) => {
  return abortableFetch(
    '/autocomplete/magic',
    {
      params,
    },
    'magicFilterController',
  );
};
