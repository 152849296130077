const dev = ['DEV', 'LOCAL'].includes(process.env.NX_ENV);
const variables = {
  blue: '#4062bb',
  blueAlt1: '#4461b7',
  blueAlt2: '#4761b5',
  blueHover: '#2c4482',
  darkBlue: '#242a45',
  darkBlueAlt1: '#112053',
  darkBlueText: '#141c26',
  darkBlueAlt2: '#0b206e',
  formLabel: 'rgba($darkBlue, 0.8)',

  lightGray: '#ebebeb',
  paleBlue: '#f7f8fe',
  lightGrey: '#f7f7f7',
  lightGreyAlt1: '#f7f8fc',
  lightGreyBlue: '#f7f9ff',
  highlightLightBlue: '#ebedff',
  greyText: '#3a3f58',
  blueText: '#2e4685',
  mediumGreyText: '#636573',
  mediumLightGreyText: '#757578',
  lightGreyText: '#c4c4c4',
  disabledGrey: '#9497a4',
  red: '#E5243E',
  errorRed: '#e31818',
  slightlyLightRed: '#e24352',
  headerLightBlue: '#eff2ff',
  headerLightBlue50Lighter: '#f7f8ff',
  headerLightBlue50Lighter75Opacity: '#f4f6ffbf',
  latestAdvanceColor: '#645da2',
  white: '#fff',

  hrColor: '#ddd',

  buttonBlue: '$blue',
  buttonDarkBlue: '#293f94',
  buttonLightBlue: '#bdcaff',
  buttonLightBlueAlt1: '#b3c1ff',
  buttonSecondaryBlue: '$highlightLightBlue',
  buttonGray: '#f4f4f4',
  buttonTextBlue: '$blue',
  buttonTextGray: '$formLabel',

  microShadow: '0 2px 3px rgba(0, 0, 0, 0.1)',
  tinyShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  smallShadow: '0 9px 20px rgba(0, 0, 0, 0.15), 0 2px 9px rgba(0, 0, 0, 0.1)',
  bigShadow: '0 10px 20px 0 rgba(25, 36, 67, 0.08)',

  tier1: '$blue',
  tier2: '#022172',
  tier3: '#810196',
  tier4: '#f52e4d',
  ebonyClay: '$darkBlue',

  confirmGreen: '#009d53',

  // Extra values to remove warnings
  white: '#fff',
  black: '#000',
  transparent: '#0000',
  gray: '$lightGreyText',
  grey: '$lightGreyText',
  lightBlue: '$buttonLightBlue',
  cosentyxDarkPink: '#DD075B',
};

export const colorLookup = (name, recursive = false) => {
  let value = variables[name];
  if (!value) {
    if (name && !recursive && dev && name.indexOf('#') !== 0) {
      console.warn(`Missing color in lookup '${name}', function will use '${name}' as a color`);
    }
    // If there is no color, use the given value
    if (name == '') return null;
    return name;
  }

  let m;
  while ((m = value.match(/\$([a-zA-Z0-9]+)/))) {
    const replace = colorLookup(m[1], true);
    if (replace) {
      value = value.replace(m[0], replace);
    } else if (dev) {
      console.warn(`Missing '${m[0]}' in '${value}'`);
      break;
    }
  }
  return value;
};
const hexToRGB = (hex) => {
  // strip the leading # if it's there
  hex = hex.replace(/^\s*#|\s*$/g, '');

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (hex.length == 3) {
    hex = hex.replace(/(.)/g, '$1$1');
  }
  if (hex.length == 6) {
    hex = hex + 'FF';
  }
  const r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16),
    a = parseInt(hex.slice(6, 8), 16);
  return { r, g, b, a };
};
export const interpolateColor = (hex1, hex2, percent = 50) => {
  const { r: r1, g: g1, b: b1, a: a1 } = hexToRGB(hex1);
  const { r: r2, g: g2, b: b2, a: a2 } = hexToRGB(hex2);

  return (
    '#' +
    (0 | ((1 << 8) + r1 + ((r2 - r1) * percent) / 100)).toString(16).slice(1) +
    (0 | ((1 << 8) + g1 + ((g2 - g1) * percent) / 100)).toString(16).slice(1) +
    (0 | ((1 << 8) + b1 + ((b2 - b1) * percent) / 100)).toString(16).slice(1) +
    (0 | ((1 << 8) + a1 + ((a2 - a1) * percent) / 100)).toString(16).slice(1)
  );
};
