import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { getCountryByCode, getIPApproxLocationGoogle } from '@medifind/interface';
import { getDefaultRadius, getFidelity, isPrerender, setCurrentLocation } from '@medifind/utils';
import { logEventGA4 } from '../google';
import { localStorageMF, migrateReduxLocalStorageValue } from '../utils';

export const LOCATION_IP = 'ip';
export const LOCATION_INITIAL = 'initial';
export const LOCATION_CUSTOM = 'custom';
export const LOCATION_QUERY = 'query';

const initialState = {
  locationOrigin: LOCATION_INITIAL,
  lat: null,
  lon: null,
  countryCode: null,
  stateProvinceCode: null,
  zipPostalCode: null,
  city: null,
  fidelity: 6,
  locationString: null,
  placeId: null,
  radius: 'global',
  milesOrKm: 'mi',
};
const storeName = 'location';
export const useLocationStore = create(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        set,
        reset: () => set({ ...initialState }, false, { type: 'reset' }),
        getInitialState: () => ({ ...initialState }),
      }),
      {
        name: `${process.env.NX_APP}-${storeName}`,
        version: 0,
        storage: createJSONStorage(() => localStorageMF),
        onRehydrateStorage: () => (state) => {
          migrateReduxLocalStorageValue({ state, key: 'location' });
          if (!isPrerender) {
            setIPApproxLocation();
          }
        },
      },
    ),
    {
      name: process.env.NX_APP,
      store: storeName,
    },
  ),
);

const setStoreLocation = (updatedLocation) => {
  const locationState = useLocationStore.getState();
  if (updatedLocation.fidelity <= 3 && (!updatedLocation.lat || !updatedLocation.lon)) {
    console.warn('Location requires lat & lon for this fidelity');
  } else {
    useLocationStore.setState(
      {
        ...locationState,
        ...updatedLocation,
        milesOrKm: getMilesOrKm(updatedLocation.countryCode),
      },
      false,
      { type: 'setStoreLocation' },
    );
  }
};

export const defaultCountry = (countryCodes) => {
  const location = useLocationStore.getState();
  const { fidelity, locationOrigin, countryCode } = location;
  if (countryCodes?.length > 0 && (!fidelity || fidelity === 6 || !countryCodes.includes(countryCode))) {
    getCountryByCode({ countryCode: countryCodes[0] })
      .then(({ countryCode, preferredName }) => {
        useLocationStore.setState(
          {
            locationOrigin,
            fidelity: 5,
            countryCode,
            locationString: preferredName.replace(/^United/i, 'The United'),
            radius: getDefaultRadius(5),
          },
          false,
          {
            type: 'defaultCountry',
          },
        );
      })
      .catch(() => null);
  }
};

const getMilesOrKm = (countryCode) => (countryCode ? (['US', 'GB', 'LR', 'MM'].includes(countryCode) ? 'mi' : 'km') : 'mi');

export const setIPApproxLocation = () => {
  const locationState = useLocationStore.getState();
  if (locationState.locationOrigin !== LOCATION_INITIAL) return;
  return getIPApproxLocationGoogle()
    .then(({ result }) => {
      const {
        city,
        countryCode,
        country,
        stateProvinceCode,
        stateProvince,
        zipPostalCode,
        addressLine1,
        locationString,
        placeId,
        lat,
        lon,
      } = result;
      const fidelity = getFidelity(addressLine1, zipPostalCode, city, stateProvinceCode, countryCode);
      const defaultRadius = getDefaultRadius(fidelity);
      const data = {
        lat: lat,
        lon: lon,
        fidelity,
        city,
        countryCode,
        country,
        stateProvinceCode,
        stateProvince,
        zipPostalCode,
        streetAddress: addressLine1,
        locationString,
        locationOrigin: LOCATION_IP,
        radius: defaultRadius,
        placeId,
      };
      if (process.env.NX_APP === 'MediFind-Pro') {
        logEventGA4('location', {
          action: 'set_from_ip',
          lat_lon: `${lat}, ${lon}`,
          street_address: addressLine1,
          city,
          state: stateProvinceCode,
          postal_code: zipPostalCode,
          country: countryCode,
        });
      }
      setStoreLocation(data);
    })
    .catch((e) => {
      setStoreLocation({
        locationOrigin: LOCATION_IP,
      });
    });
};

export const setBrowserLocation = () => setCurrentLocation(setStoreLocation);
export const setCustomLocation = (place) => {
  const { addressLine1, locationString, stateProvince, stateProvinceCode, city, countryCode, country, zipPostalCode, lat, lon } = place;
  const locationState = useLocationStore.getState();

  // Skip if clearing a cleared value
  if (locationState.locationOrigin === LOCATION_CUSTOM && !locationState.countryCode && !countryCode) {
    return;
  }
  const fidelity = getFidelity(addressLine1, zipPostalCode, city, stateProvinceCode, countryCode);
  const defaultRadius = getDefaultRadius(fidelity);
  const data = {
    fidelity,
    lat,
    lon,
    city,
    countryCode,
    country,
    zipPostalCode,
    stateProvinceCode,
    stateProvince,
    streetAddress: addressLine1,
    locationString,
    locationOrigin: LOCATION_CUSTOM,
    radius: defaultRadius,
  };
  if (process.env.NX_APP === 'MediFind-Pro') {
    logEventGA4('location', {
      action: 'set_custom_location',
      lat_lon: `${lat}, ${lon}`,
      street_address: addressLine1,
      city,
      state: stateProvinceCode,
      postal_code: zipPostalCode,
      country,
    });
  }
  setStoreLocation(data);
};
export const setLocationFromQuery = ({
  fidelity,
  lat,
  lon,
  country,
  countryCode,
  city,
  zipPostalCode,
  stateProvince,
  stateProvinceCode,
  locationString,
  streetAddress,
}) => {
  const data = {
    fidelity,
    lat,
    lon,
    country,
    countryCode,
    city,
    zipPostalCode,
    stateProvince,
    stateProvinceCode,
    locationString,
    streetAddress,
    locationOrigin: LOCATION_QUERY,
  };
  if (process.env.NX_APP === 'MediFind-Pro') {
    logEventGA4('location', {
      action: 'set_from_query',
      lat_lon: `${lat}, ${lon}`,
      street_address: streetAddress,
      city,
      state: stateProvinceCode,
      postal_code: zipPostalCode,
      country: countryCode,
    });
  }
  setStoreLocation(data);
};
